@import '../home';

@import '~@mshops-web-components/newsletter-modal/styles/default/mobile';

@import '~@mshops-components-library/common/src/Components/Section/styles/mobile';
@import '~@mshops-components-library/slide/src/styles/mobile';
@import '~@mshops-components-library/slider/src/styles/original/mobile';
@import '~@mshops-components-library/tab-with-items/src/styles/base/mobile';
@import '~@mshops-components-library/card-action/src/styles/mobile';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.mobile';

@import '../../../../components/payment-data/mobile';
@import '../../../../components/badge-carousel/styles/mobile';
@import '../../../../components/site-shopping-info/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../components/message/styles/mobile';
@import '../../../../appearance/buyerInfo/styles/mobile';

// Contact Page
@import '../../../../components/contact-form/styles/mobile';
@import '../../../../components/contact-info/styles/mobile';

// How Buy Landing
@import '../../../../components/HowBuyInfo/styles/mobile';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/mobile';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/mobile';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/mobile';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/mobile';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/mobile';

// Not Found Page for CCP
@import '../../../../components/NotFoundCCP/styles/mobile';

// Protected Purchase Landing
@import '../../../../components/ProtectedPurchaseInfo/styles/mobile';

// Splinter mobile
@import '~@mshops-components-library/splinter-grid/styles/default/mobile';

.home {
  padding: rem($small-home-padding);

  &.home--home_deco {
    padding-top: 0;

    &.home--with-flash {
      padding-top: rem($flash-component-height-fashion);
    }
  }
}

/* Modifiers for flash component and banner components */
.nav-header__banner {
  background-color: $light-gray;
  display: block;
  line-height: 0;
  padding-top: rem($small-header-height);
  width: 100%;
}

.nav-header__banner-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: rem(96px);
  width: 100%;
}

.header-wrapper--with-flash ~ .nav-header__banner {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-flash {
  padding-top: rem($small-header-height + $flash-component-height);
}

.home--with-banner {
  padding-top: 0;
}

.recommendations > .container {
  margin-bottom: 0;
}

@media (width >= 768px) {
  .home--with-flash {
    padding-top: rem($large-header-height + $flash-component-height);
  }
}
